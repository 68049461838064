import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { ArticleUnitDetails } from '../../models';

@Component({
  selector: 'mpdm-article-item-unit',
  standalone: true,
  templateUrl: './article-item-unit.component.html',
  styleUrls: ['./article-item-unit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf],
})
export class ArticleItemUnitComponent {
  @HostBinding('class') class = 'mpdm-article-item-unit';

  @Input()
  articleUnitDetails!: ArticleUnitDetails;
}
