<div class="purchase-requisition-positions">
  <span
    *ngIf="hasRequisitionUnapprovedPositions; else totalPositionsCount"
    class="purchase-requisition-positions__count"
  >
    <span class="purchase-requisition-positions__count--not-approved">{{ approvedPositionsCount }}</span>
    /
    <span>{{ purchaseRequisition.totalPositionCount }}</span>
  </span>

  <ng-template #totalPositionsCount>
    <span class="purchase-requisition-positions__count--approved">
      {{ purchaseRequisition.totalPositionCount }}
    </span>
  </ng-template>

  <span *ngIf="showLabel" class="purchase-requisition-positions__label">Positionen</span>
</div>
