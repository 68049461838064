<span class="article-item-unit__unit-name">{{ articleUnitDetails.unit }} </span>
<span class="article-item-unit__unit-long-name">{{ articleUnitDetails.mappedUnitLongName }} </span>

<span
  *ngIf="articleUnitDetails.unit !== articleUnitDetails.baseUnit && articleUnitDetails.quantityOfBaseUnit"
  class="item-base-unit"
>
  (<span class="item-base-unit__base-unit-quantity">{{ articleUnitDetails.quantityOfBaseUnit }}</span
  >&nbsp;<span
    *ngIf="articleUnitDetails.mappedBaseUnitLongName; else baseUnit"
    class="item-base-unit__base-unit-long-name"
    >&nbsp;{{ articleUnitDetails.mappedBaseUnitLongName }}</span
  >)

  <ng-template #baseUnit>
    <span class="item-base-unit__base-unit-name">{{ articleUnitDetails.baseUnit }}</span>
  </ng-template>
</span>
