import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PageLink } from '@mp/shared/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { CountUpStatComponent } from '@mpcm/shared';
import { ArticleCounts } from '../../../models';

@Component({
  selector: 'mpdm-article-counts-widget',
  standalone: true,
  templateUrl: './article-counts-widget.component.html',
  styleUrls: ['./article-counts-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, DashboardWidgetComponent, CountUpStatComponent],
})
export class ArticleCountsWidgetComponent {
  @Input() title!: string;
  @Input() icon!: string;
  @Input() featurePageLink: PageLink;
  @Input() enabledByPermission = false;
  @Input() noPermissionText = '';
  @Input() data!: ArticleCounts;

  @Output() readonly searchTermChange: EventEmitter<string> = new EventEmitter<string>();

  handleSearch(searchTerm: string): void {
    this.searchTermChange.emit(searchTerm);
  }
}
