import { Pipe, PipeTransform } from '@angular/core';

import { ArticlePriceDetails } from '../models';

@Pipe({
  name: 'mpdmArticleItemUnitPrice',
  standalone: true,
})
export class ArticleItemUnitPricePipe implements PipeTransform {
  transform(articleItemPrice: ArticlePriceDetails): number {
    if (articleItemPrice.baseUnit !== articleItemPrice.unit && articleItemPrice.quantityOfBaseUnit) {
      return (articleItemPrice.price ?? 0) / articleItemPrice.quantityOfBaseUnit;
    }
    return 0;
  }
}
