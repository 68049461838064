import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { ArticleNameComponent, ArticleSupplierNameComponent, SupplierArticleNumberComponent } from '@mpcm/shared';

import { ArticleItemDetails } from '../../models';

@Component({
  selector: 'mpdm-article-item-details',
  standalone: true,
  templateUrl: './article-item-details.component.html',
  styleUrls: ['./article-item-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,

    MatTooltipModule,
    MatIconModule,

    ArticleNameComponent,
    ArticleSupplierNameComponent,
    SupplierArticleNumberComponent,
  ],
})
export class ArticleItemDetailsComponent {
  @HostBinding('class') readonly class = 'mpdm-article-item-details';

  @Input()
  articleDetails!: ArticleItemDetails;

  @Input()
  displayClinicArticleLabel = true;
}
