import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AddressInfo } from '../../models';

@Component({
  selector: 'mpdm-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressInfoComponent {
  @Input()
  addressInfo!: AddressInfo;
}
