<div class="address-info truncate" [title]="addressInfo | mpdmFormattedAddressInfo">
  <span class="address-info__street truncate">
    <span *ngIf="addressInfo?.additionalInformation" class="address-info__additional"
      >{{ addressInfo?.additionalInformation }},</span
    >
    {{ addressInfo?.street }},</span
  >

  <span class="address-info__city">{{ addressInfo?.zipCode }} {{ addressInfo?.city }}</span>
</div>
