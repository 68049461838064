import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { ArticlePriceDetails } from '../../models';
import { ArticleItemMainPriceComponent } from '../article-item-main-price/article-item-main-price.component';
import { ArticleItemUnitPriceComponent } from '../article-item-unit-price/article-item-unit-price.component';

@Component({
  selector: 'mpdm-article-item-price',
  standalone: true,
  templateUrl: './article-item-price.component.html',
  styleUrls: ['./article-item-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, ArticleItemUnitPriceComponent, ArticleItemMainPriceComponent],
})
export class ArticleItemPriceComponent {
  @HostBinding('class') readonly class = 'mpdm-article-item-price';

  @Input()
  articlePriceDetails!: ArticlePriceDetails;
}
