<mp-dashboard-widget
  *ngIf="pendingRequisitionsCounts$ | async as pendingRequisitionsCounts"
  widgetTitle="Anforderungen (Offen)"
  icon="fact_check"
  [showSearchField]="false"
  [enabledByPermission]="true"
>
  <div class="requisition-statistics">
    <mpcm-count-up-stat
      class="requisition-statistics__pending"
      [statValue]="pendingRequisitionsCounts.pendingCount"
      [statLabel]="'Offen'"
      [link]="link"
      [queryParams]="pendingRequisitionsParams"
      [ngClass]="{
        'mpcm-count-up-stat--success': pendingRequisitionsCounts.pendingCount === 0
      }"
    ></mpcm-count-up-stat>

    <mpcm-count-up-stat
      class="requisition-statistics__failed"
      [statValue]="pendingRequisitionsCounts.failedCount"
      [statLabel]="'mit Problemen'"
      [link]="link"
      [queryParams]="failedPendingRequisitionsParams"
      [ngClass]="{
        'mpcm-count-up-stat--danger': pendingRequisitionsCounts.failedCount > 0
      }"
    ></mpcm-count-up-stat>
  </div>
</mp-dashboard-widget>
