import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { PurchaseRequisitionFilterState } from '../../../models';
import { RequisitionFilterStateNamePipe } from '../../pipes';
import { getRequisitionFilterStateCssModifier } from '../../utils';

@Component({
  selector: 'mpdm-requisition-state-name',
  standalone: true,
  templateUrl: './requisition-state-name.component.html',
  styleUrls: ['./requisition-state-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RequisitionFilterStateNamePipe],
})
export class RequisitionStateNameComponent {
  readonly componentCssClass = 'mpdm-requisition-state-name';

  @HostBinding('class')
  get class(): string {
    return `${this.componentCssClass} ${this.getComponentStateCssClass()}`;
  }

  @Input()
  active = false;

  @Input()
  set requisitionState(value: PurchaseRequisitionFilterState) {
    this._requisitionState = value;
  }

  get requisitionState(): PurchaseRequisitionFilterState {
    return this._requisitionState;
  }

  private _requisitionState: PurchaseRequisitionFilterState = PurchaseRequisitionFilterState.Pending;

  readonly RequisitionState = PurchaseRequisitionFilterState;

  private getComponentStateCssClass(): string {
    let stateCssModifier = '';

    if (!this.active) {
      stateCssModifier = '--inactive';
    } else {
      stateCssModifier = getRequisitionFilterStateCssModifier(this._requisitionState);
    }

    return stateCssModifier ? this.componentCssClass + stateCssModifier : '';
  }
}
