<mpcm-article-name
  class="article-item-details__article-title truncate"
  [articleType]="articleDetails.articleType"
  [articleName]="articleDetails.name"
  [isMatchedClinicArticle]="articleDetails.isMatchedClinicArticle"
/>

<div class="article-item-details__supplier-info">
  <mpcm-article-supplier-name
    *ngIf="articleDetails.supplierName as supplierName"
    class="supplier-info__supplier-name-container truncate"
    [supplierName]="supplierName"
    [isMainSupplier]="articleDetails.isMainSupplier"
  />

  <mpcm-supplier-article-number
    *ngIf="articleDetails.supplierArticleNumber as supplierArticleNumber"
    class="supplier-info__ref-data-container truncate"
    [supplierArticleNumber]="supplierArticleNumber"
  />

  <span
    *ngIf="articleDetails.articleType === 'ClinicArticle' && articleDetails.articleNumber"
    class="supplier-info__clinic-article-info-container truncate"
    [matTooltip]="'Klinikartikelnr.: ' + articleDetails.articleNumber"
  >
    <mat-icon class="material-icons-outlined supplier-info__clinic-icon"> home_work </mat-icon>
    <span class="truncate supplier-info__clinic-article-label" *ngIf="displayClinicArticleLabel">
      Klinikartikelnr.:
    </span>
    <span class="font-mono truncate">{{ articleDetails.articleNumber }}</span>
  </span>
</div>
