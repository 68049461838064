export enum ErpTransferState {
  Pending = 'Pending',
  Processing = 'Processing',
  Transferred = 'Transferred',
  Failed = 'Failed',
}

export interface ErpStateBase {
  changedAt: string; //Date;
  type: ErpTransferState;
}

export interface PendingErpState extends ErpStateBase {
  type: ErpTransferState.Pending;
}

export interface ProcessingErpState extends ErpStateBase {
  type: ErpTransferState.Processing;
}

export interface TransferredErpState extends ErpStateBase {
  type: ErpTransferState.Transferred;
  erpRequisitionNumber: string;
}

export interface FailedErpState extends ErpStateBase {
  type: ErpTransferState.Failed;
  error: FailedErpStateError;
}

export interface FailedErpStateError {
  errorMessages: Array<string>;
  // statusCode: number;
}

export type ErpState = PendingErpState | ProcessingErpState | TransferredErpState | FailedErpState;
