import { Pipe, PipeTransform } from '@angular/core';

import { PurchaseRequisitionFilterState } from '../../models';

@Pipe({
  name: 'mpdmRequisitionFilterStateName',
  standalone: true,
})
export class RequisitionFilterStateNamePipe implements PipeTransform {
  transform(value: PurchaseRequisitionFilterState): string {
    switch (value) {
      case PurchaseRequisitionFilterState.Pending:
        return 'IN FREIGABE';

      case PurchaseRequisitionFilterState.Approved:
        return 'FREIGEGEBEN';

      case PurchaseRequisitionFilterState.Rejected:
        return 'ABGELEHNT';

      case PurchaseRequisitionFilterState.Failed:
        return 'FEHLGESCHLAGEN';

      default:
        return '';
    }
  }
}
