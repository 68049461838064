import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { PurchaseRequisition, RequisitionState } from '../../models';

@Component({
  selector: 'mpdm-purchase-requisition-positions-count',
  standalone: true,
  templateUrl: './purchase-requisition-positions-count.component.html',
  styleUrls: ['./purchase-requisition-positions-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf],
})
export class PurchaseRequisitionPositionsCountComponent {
  @HostBinding('class') class = 'mpdm-purchase-requisition-positions-count';

  @Input()
  approvedPositionsCount!: number;

  @Input() purchaseRequisition!: PurchaseRequisition;

  @Input() showLabel = true;

  readonly RequisitionState = RequisitionState;

  get hasRequisitionUnapprovedPositions(): boolean {
    const isRequisitionApprovable: boolean =
      this.purchaseRequisition.state.type === RequisitionState.Approved || this.purchaseRequisition.isApprovable;

    if (!isRequisitionApprovable) {
      return false;
    }

    return this.approvedPositionsCount < this.purchaseRequisition.totalPositionCount;
  }
}
