import { CurrencyPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { ArticlePriceDetails } from '../../models';
import { ArticleItemUnitPricePipe } from '../../pipes';

@Component({
  selector: 'mpdm-article-item-unit-price',
  standalone: true,
  templateUrl: './article-item-unit-price.component.html',
  styleUrls: ['./article-item-unit-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, ArticleItemUnitPricePipe, CurrencyPipe],
})
export class ArticleItemUnitPriceComponent {
  @HostBinding('class') readonly class = 'mpdm-article-item-unit-price';

  @Input()
  articlePriceDetails!: ArticlePriceDetails;
}
