import { Pipe, PipeTransform } from '@angular/core';

import { formatAddress } from '../../utils';
import { AddressInfo } from '../models';

@Pipe({
  name: 'mpdmFormattedAddressInfo',
})
export class FormattedAddressInfoPipe implements PipeTransform {
  transform(addressInfo: AddressInfo): string {
    return formatAddress(addressInfo);
  }
}
