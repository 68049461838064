import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { QueryParams } from '@core/shared/util';
import { PageResponse, Pagination } from '@mp/shared/data-access';

import { PurchaseRequisition } from '../models';

@Injectable()
export class RecentPurchaseRequisitionsService {
  private readonly baseUrl = '/api/document-management/purchase-requisitions';

  private readonly recentRequisitionsPagination: Pagination = {
    page: 1,
    pageSize: 5,
  };

  constructor(private readonly http: HttpClient) {}

  /**
   * Fetches the recent purchase requisitions.
   * @returns Observable of the recent purchase requisitions.
   */
  fetchRecentPurchaseRequisitions(): Observable<PurchaseRequisition[]> {
    const { page, pageSize } = this.recentRequisitionsPagination;
    const params = QueryParams.build().page(page).pageSize(pageSize).toHttpParams();

    return this.http.get<PageResponse<PurchaseRequisition>>(this.baseUrl, { params }).pipe(map(({ data }) => data));
  }
}
