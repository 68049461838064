import { UserInfo } from '@mp/shared/data-access';

import { ErpState } from './erp-transfer-state';

export enum RequisitionState {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface StateBase {
  changedAt: string;
  changedBy: UserInfo;
  type: RequisitionState;
}

export interface PendingState extends StateBase {
  type: RequisitionState.Pending;
}

export interface ApprovedState extends StateBase {
  type: RequisitionState.Approved;
  erpState: ErpState;
}

export interface RejectedState extends StateBase {
  type: RequisitionState.Rejected;
  reason: string;
}

export type PurchaseRequisitionState = PendingState | ApprovedState | RejectedState;
