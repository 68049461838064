import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'mpdm-article-item-main-price',
  standalone: true,
  templateUrl: './article-item-main-price.component.html',
  styleUrls: ['./article-item-main-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CurrencyPipe],
})
export class ArticleItemMainPriceComponent {
  @HostBinding('class') readonly class = 'mpdm-article-item-main-price';

  @Input()
  price?: number;
}
