import { PurchaseRequisitionFilterState } from '../../models';
import { ErpState, ErpTransferState, PurchaseRequisitionState, RequisitionState } from '../models';

export function getRequisitionFilterStateCssModifier(filterState: PurchaseRequisitionFilterState): string {
  switch (filterState) {
    case PurchaseRequisitionFilterState.Approved:
      return '--approved';

    case PurchaseRequisitionFilterState.Pending:
      return '--pending';

    case PurchaseRequisitionFilterState.Rejected:
      return '--rejected';

    case PurchaseRequisitionFilterState.Failed:
      return '--failed';
  }
}

export function mapRequisitionStateToFilterState(state: PurchaseRequisitionState): PurchaseRequisitionFilterState {
  switch (state.type) {
    case RequisitionState.Pending:
      return PurchaseRequisitionFilterState.Pending;

    case RequisitionState.Rejected:
      return PurchaseRequisitionFilterState.Rejected;

    case RequisitionState.Approved:
      return getRequisitionFilterStateByErpState(state.erpState);
  }
}

function getRequisitionFilterStateByErpState(erpState: ErpState): PurchaseRequisitionFilterState {
  switch (erpState.type) {
    case ErpTransferState.Failed:
      return PurchaseRequisitionFilterState.Failed;

    case ErpTransferState.Transferred:
      return PurchaseRequisitionFilterState.Approved;

    case ErpTransferState.Processing:
    case ErpTransferState.Pending:
      return PurchaseRequisitionFilterState.Pending;
  }
}
